import { http, createFormData } from '@utils/index';

interface Props {
	id: number;
	body: {
		name?: string;
		slug?: string;
		logo?: File | null;
		meta?: {
			support_url?: string;
			colours?: {
				header_background?: string;
				primary_accent?: string;
				secondary_accent?: string;
				tertiary_accent?: string;
			};
		};
	};
}

const update = (props: Props) => {
	return http.post<APIResponse<OrganisationRes>>(
		`/organisations/${props.id}`,
		createFormData([
			{
				key: '_method',
				value: 'put',
			},
			{
				key: 'name',
				value: props.body.name,
			},
			{
				key: 'slug',
				value: props.body.slug,
			},
			{
				key: 'logo',
				value: props.body.logo,
			},
			{
				key: 'meta[support_url]',
				value: props.body.meta?.support_url,
			},
			{
				key: 'meta[colours][header_background]',
				value: props.body.meta?.colours?.header_background
			},
			{
				key: 'meta[colours][primary_accent]',
				value: props.body.meta?.colours?.primary_accent
			},
			{
				key: 'meta[colours][secondary_accent]',
				value: props.body.meta?.colours?.secondary_accent
			},
			{
				key: 'meta[colours][tertiary_accent]',
				value: props.body.meta?.colours?.tertiary_accent
			},
		]),
		{
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}
	);
};
export default update;
