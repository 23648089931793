import { useState, useContext, createContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { organisationTheme } from '@utils/index';

interface OrganisationContextProps {
	organisation?: SelectedOrganisation;
	selectOrganisation: (org?: SaOrganisationsRes, redirect?: boolean) => void;
	clearOrganisation: () => void;
	syncOrganisation: (user: CurrentUserRes) => void;
	initialOrgLs: (
		action: 'get' | 'set',
		value?: boolean
	) => boolean | undefined;
}

const OrganisationContext = createContext<OrganisationContextProps>({
	organisation: undefined,
	selectOrganisation: () => {},
	clearOrganisation: () => {},
	syncOrganisation: () => {},
	initialOrgLs: () => false,
});

const useProvideOrganisation = () => {
	// -------------------------------------------------
	// Internal functions
	const setLocalStorage = (
		organisation?: OrganisationContextProps['organisation']
	) => {
		if (organisation) {
			localStorage.setItem('organisation', JSON.stringify(organisation));
		} else {
			localStorage.removeItem('organisation');
		}
	};
	const getLocalStorage = () => {
		const organisation = localStorage.getItem('organisation');
		if (organisation) {
			return JSON.parse(
				organisation
			) as OrganisationContextProps['organisation'];
		}
		return undefined;
	};
	const initialOrgLs = (action: 'get' | 'set', value?: boolean) => {
		if (action === 'get') {
			const initialSet = localStorage.getItem('org_initial_set');
			if (initialSet === null) return false;
			return true;
		} else {
			if (value) {
				localStorage.setItem('org_initial_set', 'true');
			} else {
				localStorage.removeItem('org_initial_set');
			}
		}
	};

	// -------------------------------------------------
	// State
	const navigate = useNavigate();

	const [organisation, setOrganisation] = useState<
		OrganisationContextProps['organisation']
	>(getLocalStorage());

	// -------------------------------------------------
	// Public functions
	const selectOrganisation: OrganisationContextProps['selectOrganisation'] = (
		org,
		redirect = true
	) => {
		if (redirect) navigate('/');

		if (org === undefined) {
			sessionStorage.setItem('pause_org_sync', 'true');
			setTimeout(() => {
				sessionStorage.removeItem('pause_org_sync');
			}, 1000);

			clearOrganisation();
			return;
		}

		setOrganisation({
			id: org.id,
			permissions: org.permissions || [],
			name: org.name,
			slug: org.slug,
			plan_id: org.plan_id,
			logo_thumbnail: org.logo_thumbnail,
			meta: org.meta,
			stripe_id: org.stripe_id,
			active: org.active,
			seated_at: org.seated_at,
			source: org.source
		});
		setLocalStorage({
			id: org.id,
			permissions: org.permissions || [],
			name: org.name,
			slug: org.slug,
			plan_id: org.plan_id,
			logo_thumbnail: org.logo_thumbnail,
			meta: org.meta,
			stripe_id: org.stripe_id,
			active: org.active,
			seated_at: org.seated_at,
			source: org.source
		});

		organisationTheme.setTheme({
			colours: {
				ui: {
					header: org.meta?.colours?.header_background,
				},
				accents: {
					primary: org.meta?.colours?.primary_accent,
					secondary: org.meta?.colours?.secondary_accent,
					tertiary: org.meta?.colours?.tertiary_accent,
				},
			},
		});
	};
	const syncOrganisation = (user: CurrentUserRes) => {
		const paused = sessionStorage.getItem('pause_org_sync');
		if (paused !== null) return;

		const found = user?.organisations.find(
			(org) => org.id === organisation?.id
		);

		let syncOrg = undefined;

		// For super admin
		if (user.is_super_admin) {
			if (found !== undefined) syncOrg = found;
			else syncOrg = undefined;
		}
		// For other users
		else {
			const initialSet = initialOrgLs('get');
			if (!initialSet) {
				initialOrgLs('set', true);
				syncOrg = user?.organisations[0];
			} else {
				if (organisation === undefined) {
					syncOrg = undefined;
				} else if (found !== undefined) {
					syncOrg = found;
				} else {
					syncOrg = undefined;
				}
			}
		}

		// Select the org, with redirect disabled
		selectOrganisation(syncOrg, false);

		// Set the theme
		if (syncOrg && syncOrg.meta?.colours !== null) {
			organisationTheme.setTheme({
				colours: {
					ui: {
						header: syncOrg.meta?.colours?.header_background,
					},
					accents: {
						primary: syncOrg.meta?.colours?.primary_accent,
						secondary: syncOrg.meta?.colours?.secondary_accent,
						tertiary: syncOrg.meta?.colours?.tertiary_accent,
					},
				},
			});
		} else {
			organisationTheme.resetTheme();
		}
	};
	const clearOrganisation: OrganisationContextProps['clearOrganisation'] =
		() => {
			setOrganisation(undefined);
			setLocalStorage(undefined);
			organisationTheme.resetTheme();
		};

	// -------------------------------------------------
	// Return
	return {
		organisation,
		selectOrganisation,
		clearOrganisation,
		syncOrganisation,
		initialOrgLs,
	};
};

interface ProvideOrganisationProps {
	children: React.ReactNode;
}

export const ProvideOrganisation = ({ children }: ProvideOrganisationProps) => {
	const organisation = useProvideOrganisation();
	return (
		<OrganisationContext.Provider value={organisation}>
			{children}
		</OrganisationContext.Provider>
	);
};

export const useOrganisation = () => {
	return useContext(OrganisationContext);
};
