import { translate } from '@lang/index';
import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth, useOrganisation } from '@hooks/index';
import C from '@root/constants';
// Hooks
import { useToast } from '@hooks/index';

const Redirect: React.FC = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { setAuth } = useAuth();
	const { organisation } = useOrganisation();
	const { addToast } = useToast();

	const navigateToPath = (path: string, cb?: () => void) => {
		if (location.pathname === path) return;
		setTimeout(() => {
			navigate(path);
			cb && cb();
		}, 300);
	};

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const intent = urlParams.get('intent');
		switch (intent) {
			case 'email_verified':
				navigateToPath('/', () => {
					addToast({
						title: translate(
							'toast_email_successfully_verified_title'
						),
						message: translate(
							'toast_email_successfully_verified_message'
						),
						type: 'success',
					});
				});
				break;
			case 'auth':
				setAuth(true);
				navigateToPath('/');
				break;
			case 'auth_kappa':
				window.location.href = `${C.MARKETING_URL}?source=kappa`;
				break;
			case 'billing':
				navigateToPath(`/${organisation?.slug}/manage`);
				break;
			case 'reset-password':
				navigateToPath('/password-reset');
				break;
			case 'openid_error':
				navigateToPath('/error/openid');
				break;
			case 'openid_error_org_id':
				navigateToPath('/error/openid-org-id');
				break;
			default:
				navigateToPath('/');
				break;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [navigate, setAuth, organisation]);

	// ----------------------------------------
	// Render
	return (
		<div className="flex min-h-screen items-center justify-center">
			<div className="flex flex-col items-center">
				<div className="mb-5 h-6 w-6 animate-spin rounded-full border-b-2 border-black"></div>
				<h1 className="mb-1 text-center text-xl font-semibold">
					{translate('redirecting')}
				</h1>
				<p className="text-center text-sm">
					{translate('redirecting_message')}
				</p>
			</div>
		</div>
	);
};

export default Redirect;
