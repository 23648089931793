import { translate } from '@lang/index';
import { useState, useEffect, useMemo } from 'react';
// import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
// api
import api from 'api';
// Hooks
import { useFormState, useToast } from '@hooks/index';
// Util
import { organisationTheme, helpers } from '@utils/index';
// Components
import Form from '@components/forms/Form';
import ColourPicker from '@components/forms/ColourPicker';
import Input from '@components/forms/Input';
import Button from '@components/partials/Button';
import FormAction from '@components/forms/FormAction';
import FormRow from '@components/forms/FormRow';
import SingleImageUpload from '@components/forms/SingleImageUpload';

interface UpdateOrgActionProps extends ActionProps {
	organisation: OrganisationRes;
}

const UpdateOrgAction: React.FC<UpdateOrgActionProps> = ({
	organisation,
	callback,
}) => {
	// -------------------------------------------------
	// State
	const formState = useFormState();
	const { addToast } = useToast();
	const queryClient = useQueryClient();
	// const navigate = useNavigate();

	const [coloursSaved, setColoursSaved] = useState(false);

	const [logo, setLogo] = useState<File | undefined | null>(undefined);
	const [name, setName] = useState(organisation.name);
	// const [slug, setSlug] = useState(organisation.slug);
	const [supportUrl, setSupportUrl] = useState(
		organisation.meta?.support_url || ''
	);

	// Colours
	const [headerBackground, setHeaderBackground] = useState(
		organisation.meta?.colours?.header_background ||
			organisationTheme.getColourByKey('--theme-ui-header-background')
	);
	const [brandPrimary, setBrandPrimary] = useState(
		organisation.meta?.colours?.primary_accent ||
			organisationTheme.getColourByKey('--theme-primary-base')
	);
	const [brandSecondary, setBrandSecondary] = useState(
		organisation.meta?.colours?.secondary_accent ||
			organisationTheme.getColourByKey('--theme-secondary-base')
	);
	const [brandTertiary, setBrandTertiary] = useState(
		organisation.meta?.colours?.tertiary_accent ||
			organisationTheme.getColourByKey('--theme-tertiary-base')
	);

	// -------------------------------------------------
	// Queries / Mutations
	const updateOrganisation = useMutation(api.organisations.current.update, {
		onSuccess: () => {
			addToast({
				title: translate('toast_updated_title', {
					name: 'Organisation',
				}),
				message: translate('toast_updated_message', {
					name: 'Organisation',
				}),
				type: 'success',
			});
			queryClient.invalidateQueries(['root.getAuthenticatedUser']);
			queryClient.invalidateQueries(['organisations.current.get']);
			// if (organisation.slug !== slug) navigate(`/${slug}/manage`); // Redirect to new slug
			callback(true);
		},
		onError: (error: AxiosAPIError) => {
			helpers.mutationErrorHandler(error, formState);
			callback(false);
		},
	});

	// -------------------------------------------------
	// Memos
	const showColours = useMemo(() => {
		if (organisation.plan === null) return false;
		else if (organisation.meta?.colours) return true;
		else if (organisation.plan != null) return true;
		return false;
	}, [organisation.plan, organisation.meta?.colours]);

	// -------------------------------------------------
	// Functions
	const onSubmit = async () => {
		const body: Partial<{
			name: string;
			slug: string;
			meta: OrganisationMeta | undefined;
			logo: File | undefined | null;
		}> = helpers.deepDiff(
			{
				name: organisation.name,
				meta: Array.isArray(organisation.meta)
					? undefined
					: organisation.meta,
			},
			{
				name,
				meta: showColours
					? {
							support_url: supportUrl || undefined,
							colours: {
								header_background: headerBackground,
								primary_accent: brandPrimary,
								secondary_accent: brandSecondary,
								tertiary_accent: brandTertiary,
							},
					  }
					: undefined,
			}
		);
		body.logo = logo;

		console.log('body', body)

		await updateOrganisation.mutateAsync({
			id: organisation.id,
			body: body,
		});
		if (Object.keys(body.meta?.colours || {}).length > 0) {
			setColoursSaved(true);
		}
	};
	const previewTheme = async () => {
		organisationTheme.setTheme({
			colours: {
				ui: {
					header: headerBackground,
				},
				accents: {
					primary: brandPrimary,
					secondary: brandSecondary,
					tertiary: brandTertiary,
				},
			},
		});
	};

	// -------------------------------------------------
	// Effects
	useEffect(() => {
		previewTheme();
		return () => {
			if (!coloursSaved) {
				organisationTheme.setTheme({
					colours: {
						ui: {
							header: organisation?.meta?.colours
								?.header_background,
						},
						accents: {
							primary:
								organisation?.meta?.colours?.primary_accent,
							secondary:
								organisation?.meta?.colours?.secondary_accent,
							tertiary:
								organisation?.meta?.colours?.tertiary_accent,
						},
					},
				});
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		headerBackground,
		brandPrimary,
		brandSecondary,
		brandTertiary,
		coloursSaved,
	]);

	// -------------------------------------------------
	// Render
	return (
		<Form onSubmit={onSubmit} state={formState} validation={true}>
			{/* Logo */}
			<FormRow title={translate('logo')}>
				<SingleImageUpload
					id="logo"
					name="logo"
					errors={formState.errors.value['logo']}
					required={false}
					formats={['image/jpeg', 'image/png']}
					value={logo}
					setValue={setLogo}
					describedBy={translate('image_described_by', {
						width: 'min 400',
						height: 400,
						type: 'png or jpeg',
					})}
					current={organisation.logo_thumbnail}
				/>
			</FormRow>
			{/* Details */}
			<FormRow title={translate('details')}>
				<Input
					id="name"
					type="text"
					label={translate('name')}
					name="name"
					value={name}
					setValue={setName}
					errors={formState.errors.value['name']}
					required={true}
				/>
				{organisation.plan !== null && (
					<Input
						id="meta[support_url]"
						type="url"
						label={translate('support_url')}
						name="meta[support_url]"
						value={supportUrl}
						setValue={setSupportUrl}
						errors={formState.errors.value['meta.support_url']}
						required={false}
						describedBy={translate('support_url_description')}
					/>
				)}
			</FormRow>
			{/* Theme */}
			{showColours && (
				<FormRow title={translate('theme')}>
					<h3 className="mb-2.5 text-base font-medium">Header</h3>
					<div className="mb-5 rounded-md border border-border p-2.5 last:mb-0">
						<ColourPicker
							label={'Background'}
							id="headerBackground"
							name="headerBackground"
							value={headerBackground}
							setValue={setHeaderBackground}
							errors={formState.errors.value['headerBackground']}
							required={true}
						/>
					</div>

					<h3 className="mb-2.5 text-base font-medium">Accents</h3>
					<div className="mb-5 rounded-md border border-border p-2.5 last:mb-0">
						<ColourPicker
							label={'Primary'}
							id="brandPrimary"
							name="brandPrimary"
							value={brandPrimary}
							setValue={setBrandPrimary}
							errors={formState.errors.value['brandPrimary']}
							required={true}
						/>
						<ColourPicker
							label={'Secondary'}
							id="brandSecondary"
							name="brandSecondary"
							value={brandSecondary}
							setValue={setBrandSecondary}
							errors={formState.errors.value['brandSecondary']}
							required={true}
						/>
						<ColourPicker
							label={'Tertiary'}
							id="brandTertiary"
							name="brandTertiary"
							value={brandTertiary}
							setValue={setBrandTertiary}
							errors={formState.errors.value['brandTertiary']}
							required={true}
						/>
					</div>
				</FormRow>
			)}
			{/* Update */}
			<FormAction
				loading={{
					is: updateOrganisation.isLoading,
					message: translate('updating'),
				}}
				error={{
					is: updateOrganisation.isError,
					message: formState.errorMessage.value,
				}}>
				<Button
					theme="primary"
					type="submit"
					disabled={formState.lock.value}>
					{translate('update')}
				</Button>
			</FormAction>
		</Form>
	);
};

export default UpdateOrgAction;
